.block.form .ui.form .ui.message {
    display: flex;
    width: 80%;
    margin: 3rem auto 0;
}

.block.form .ui.form .row.row-padded-top {
    padding-top: 3rem;
}

.block.form .ui.button.add-element:hover {
    color: white;
}
